import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "@tanstack/react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { router } from "./router";
import { queryClient } from "./core/queryClient/queryClient";
import zhCN from "antd/locale/zh_CN";
import 'dayjs/locale/zh-cn';
import "./styles/tailwind.css";
import "./styles/vars.css";

import { SnackbarProvider } from "notistack";
import { ConfigProvider } from "antd";

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {},
          components: {
            Layout: {
              headerBg: "#FFFFFF",
            },
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        <SnackbarProvider />
      </ConfigProvider>
    </StrictMode>
  );
}
