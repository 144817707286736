// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { createRouter } from "@tanstack/react-router";
import { queryClient } from "./core/queryClient/queryClient";

// Create a new router instance
const router = createRouter({
  basepath: import.meta.env.VITE_ROUTER_BASE,
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
});

export { router };
